@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
/*lbrown*/
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900|Noto+Serif+JP:200,300,400,500,600,700,900&amp;subset=japanese);
.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #333333;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-style: normal;
  font-weight: bold;
  word-wrap: break-word;
  font-size: 16px;
  min-width: 100%;
  overflow: hidden;
  background: url(../images/common/body_bg.png);
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
}

@media only screen and (max-width: 640px) {
  .inner {
    padding: 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .inner {
    padding: 64px 0 20px;
  }
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
  max-width: 1200px;
  padding-bottom: 100px;
  margin-bottom: 50px;
}

@media only screen and (max-width: 834px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

a {
  transition: 1s;
}

.imgR, .imgL {
  float: none	!important;
  margin-left: 0;
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    max-width: 100% !important;
    text-align: center;
  }
}

.imgL {
  float: none;
  max-width: 100% !important;
}

.mincho {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  text-align: center;
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

.hed_left {
  padding: 30px 10px;
  border-top: 5px solid #ab6300;
}

@media only screen and (max-width: 834px) {
  .hed_left {
    padding-bottom: 15px;
  }
}

.hed_right {
  position: fixed;
  right: 0;
  z-index: 2;
}

@media only screen and (max-width: 834px) {
  .hed_right {
    justify-content: center;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .hed_right {
    justify-content: center;
  }
}

.hed_right .tel2 {
  font-size: 1.25em;
  color: #ffffff;
  font-weight: bold;
  writing-mode: vertical-rl;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .hed_right .tel2 {
    font-size: 1.25em;
    margin-right: 0;
    justify-content: flex-start;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .hed_right .tel2 {
    font-size: 1.25em;
  }
}

.hed_right .tel2 a {
  color: #ffffff;
  display: block;
  font-weight: bold;
}

.hed_right .tel_time {
  background: #ab6300;
  padding: 31px 18px;
  border-radius: 5px;
  height: 250px;
}

.hed_right .tel_time img {
  margin-bottom: 7px;
}

.hed_right .hed_cont a {
  background: #ff6600;
  color: #ffffff;
  font-size: 1.25em;
  padding: 19px;
  display: block;
  border-radius: 5px;
  writing-mode: vertical-rl;
  margin-bottom: 15px;
  font-weight: bold;
  height: 250px;
}

.hed_right .hed_cont a img {
  margin-bottom: 7px;
}

#header {
  background: rgba(255, 255, 255, 0.7);
}

@media only screen and (max-width: 834px) {
  #header {
    justify-content: center;
  }
}

#header .wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  #header .wrap {
    display: block;
  }
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  #header .wrap {
    display: block;
  }
}

#header #siteID {
  text-align: center;
}

@media only screen and (max-width: 834px) {
  #header #siteID {
    text-align: center;
  }
}

#header #siteID a {
  opacity: 1;
  display: block;
}

.fixed {
  position: fixed !important;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3;
  background: rgba(250, 250, 250, 0.8);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-top: 0 !important;
  padding: 10px;
}

#hed_info .address {
  text-align: right;
}

@media only screen and (max-width: 640px) {
  #hed_info .address {
    text-align: center;
  }
}

.navwrap {
  transition: 0.5s ease-in-out;
  margin: 10px;
}

@media only screen and (max-width: 834px) {
  .navwrap {
    display: none;
  }
}

@media screen and (min-width: 834px) and (max-width: 1540px) {
  .navwrap {
    width: 100%;
  }
}

.hedwrap {
  border-bottom: 5px solid #ab6300;
}

#nav_global {
  width: 100%;
}

@media only screen and (max-width: 834px) {
  #nav_global {
    display: none;
  }
}

#nav_global .inner {
  padding: 0;
}

#nav_global ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#nav_global ul .pcnone {
  display: none;
}

#nav_global ul .spnone {
  display: block;
}

@media only screen and (max-width: 640px) {
  #nav_global ul .spnone {
    display: none;
  }
}

#nav_global ul li {
  z-index: 1;
  position: relative;
  width: 100%;
  border-left: 1px dashed #7d4329;
}

#nav_global ul li:last-of-type {
  border-right: 1px dashed #7d4329;
}

#nav_global ul li a {
  width: 100%;
  display: block;
  color: #7d4329;
  padding: 0 10px;
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  #nav_global ul li a {
    font-size: 14px;
  }
}

#nav_global ul li a:hover {
  opacity: 1;
  -webkit-animation: .3s;
  animation: zoom .3s;
}

#nav_global ul ul {
  position: absolute;
  width: 200px;
  z-index: 100;
  display: block;
  background: rgba(255, 255, 255, 0.7);
  text-align: center;
  left: -65%;
  right: 0;
}

#nav_global ul ul li {
  border-bottom: 1px solid #ff6600;
  font-size: 14px;
  border-bottom: none;
}

#nav_global ul ul li a {
  color: #333333;
  padding: 15px 0;
}

#mainArea {
  max-width: 1300px;
  margin: 0 auto;
}

@media only screen and (max-width: 640px) {
  #mainArea {
    margin: auto;
  }
}

.mainwrap {
  position: relative;
  background: url(../images/common/main_bg.jpg);
  background-size: cover;
  padding: 30px 10px;
}

.mainwrap .main_txt {
  position: absolute;
  bottom: 16%;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 990px;
  height: 56px;
  background: #7d4329;
  padding: 10px;
  width: 100%;
}

@media only screen and (max-width: 834px) {
  .mainwrap .main_txt {
    height: 146px;
  }
}

.mainwrap .main_txt br:nth-of-type(1), .mainwrap .main_txt br :nth-of-type(2) {
  display: none;
}

@media only screen and (max-width: 834px) {
  .mainwrap .main_txt br:nth-of-type(1), .mainwrap .main_txt br :nth-of-type(2) {
    display: block;
  }
}

@media only screen and (max-width: 834px) {
  .mainwrap .main_txt br {
    display: block;
  }
}

.mainwrap .main_txt h1 {
  font-size: 50px;
  font-size: 2.60417vw;
  text-align: center;
  letter-spacing: 0;
  color: #ffffff;
  font-weight: bold;
  line-height: 1.5em;
}

@media only screen and (max-width: 640px) {
  .mainwrap .main_txt h1 {
    font-size: 24px !important;
    text-align: left;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .mainwrap .main_txt h1 {
    font-size: 30px !important;
    text-align: left;
  }
}

#local-keyvisual {
  position: relative;
  background-position: center top;
  z-index: 0;
}

#local-keyvisual:empty {
  display: none;
}

#local-keyvisual h1 {
  color: #fff;
  font-size: 3em;
  z-index: 1;
  position: relative;
  text-align: center;
  line-height: 0.9em;
  width: 100%;
  margin: 0 auto;
  padding: 130px 0;
  text-shadow: 1px 1px 2px #512700;
  background: rgba(81, 39, 0, 0.2);
  font-weight: bold;
}

#local-keyvisual h1:empty {
  display: none;
}

@media only screen and (max-width: 834px) {
  #local-keyvisual h1 {
    margin-bottom: 50px;
    padding: 100px 0;
    font-size: 1.8em;
  }
}

#slider {
  position: relative;
  z-index: 1;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-size: cover;
  background-position: center;
}

#local-keyvisual #local-keyvisual-bg:empty, #local-keyvisual .local-keyvisual-bg:empty {
  display: none;
}

#local-keyvisual-title:empty {
  display: none;
}

.pan1 {
  margin: 20px 0 100px;
}

@media only screen and (max-width: 834px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #000;
}

.toggleMenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 49px;
  width: 49px;
  background-size: contain;
  position: fixed;
  top: 10px;
  right: 5px;
  z-index: 100;
  transition: 0.5s ease-in-out;
  background: #7d4329;
  border-radius: 10px;
}

.toggleMenu:after {
  content: 'MENU';
  font-size: 13px;
  color: #7d4329;
  display: block;
  position: absolute;
  bottom: -50%;
  left: 8%;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .toggleMenu {
    width: 49px;
  }
}

.hide {
  transform: translateY(-200%);
}

.toggleMenu .bars {
  margin: 0 30px 0 15px;
  height: 22px;
  width: 35px;
  position: relative;
}

.toggleMenu a:hover {
  opacity: 1;
}

.toggleMenu .bars span {
  height: 3px;
  background-color: #fff;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  position: absolute;
  left: -5px;
}

.toggleMenu .bars span:nth-of-type(1) {
  width: 30px;
  top: 0;
}

.toggleMenu .bars span:nth-of-type(2) {
  width: 30px;
  top: 10px;
}

.toggleMenu .bars span:nth-of-type(3) {
  width: 30px;
  top: 20px;
}

.toggleMenu.isActive .bars span {
  right: 0;
}

.toggleMenu.isActive .bars span:nth-of-type(1), .toggleMenu.isActive .bars span:nth-of-type(3) {
  width: 30px;
  top: 10px;
}

.toggleMenu.isActive .bars span:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.toggleMenu.isActive .bars span:nth-of-type(2) {
  opacity: 0;
}

.toggleMenu.isActive .bars span:nth-of-type(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.overlayMenu {
  width: 100%;
  -webkit-transition: -webkit-transform .8s ease;
  transition: -webkit-transform .8s ease;
  transition: transform .8s ease;
  transition: transform .8s ease, -webkit-transform .8s ease;
  z-index: 10;
  position: fixed;
  top: 0;
  right: 100%;
  bottom: 0;
  background: url(../images/common/body_bg.png);
  opacity: 0;
  transition: opacity .6s ease, visibility .6s ease;
}

.fixed .overlayMenu {
  visibility: visible;
  opacity: 1;
}

.overlayMenu.isOpened {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.overlayMenu > .outer {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-size: cover;
  padding-top: 200px !important;
}

.overlayMenu > .outer .inner {
  padding: 0 100px;
}

.overlayMenu > .outer .sp_hed_right {
  margin-top: 80px;
}

@media only screen and (max-width: 834px) {
  .overlayMenu > .outer .sp_hed_right {
    display: block;
  }
  .overlayMenu > .outer .sp_hed_right .tel2 {
    color: #ffffff;
    font-weight: bold;
    font-size: 1.5em;
  }
  .overlayMenu > .outer .sp_hed_right .tel2 a {
    color: #ffffff;
    font-weight: bold;
  }
  .overlayMenu > .outer .sp_hed_right .tel2 img {
    margin-right: 7px;
  }
  .overlayMenu > .outer .sp_hed_right .tel_time {
    background: #ab6300;
    max-width: 250px;
    margin: 0 auto;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 10px;
  }
  .overlayMenu > .outer .sp_hed_right .hed_cont a {
    background: #ff6600;
    color: #ffffff;
    font-size: 1.3em;
    padding: 10px;
    display: block;
    border-radius: 5px;
    max-width: 250px;
    margin: 0 auto 10px;
  }
}

@media only screen and (max-width: 834px) and (max-width: 834px) {
  .overlayMenu > .outer .sp_hed_right .hed_cont a {
    font-size: 1em;
    padding: 10px;
  }
}

@media only screen and (max-width: 834px) {
  .overlayMenu > .outer .sp_hed_right .hed_cont a img {
    margin-right: 7px;
  }
}

@media only screen and (max-width: 834px) {
  .overlayMenu > .outer {
    padding: 20px 0;
  }
}

@media only screen and (max-width: 640px) {
  .overlayMenu > .outer ul {
    padding: 50px;
  }
}

.overlayMenu > .outer li {
  border-bottom: 1px dashed #ab6300;
}

.overlayMenu > .outer li a {
  color: #333333;
  padding: 10px;
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
}

.overlayMenu > .outer li a span {
  display: block;
  font-size: 13px;
}

.overlayMenu:before, .overlayMenu:after {
  content: "";
  height: 20px;
  background-size: cover;
  position: absolute;
  left: 10px;
  right: 10px;
}

.overlayMenu:before {
  top: 0;
}

.overlayMenu:after {
  bottom: 0;
}

@media (max-width: 835px) {
  .pcOnly {
    display: none !important;
  }
}

@media (min-width: 835px) {
  .smOnly {
    display: none !important;
  }
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  text-align: center;
}

footer#global_footer .inner {
  padding: 50px 10px 80px;
}

@media only screen and (max-width: 834px) {
  footer#global_footer .inner {
    padding: 30px 0;
  }
}

footer#global_footer a {
  text-decoration: none;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

footer#global_footer #nav_footer ul {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

footer#global_footer #nav_footer li {
  text-align: center;
}

footer#global_footer #nav_footer li a {
  text-decoration: none;
  padding: 2px 10px;
  display: block;
  color: #ffffff;
}

footer#global_footer #nav_footer li a:hover {
  opacity: 0.6;
}

footer#global_footer #nav_footer li a span {
  display: block;
  font-size: 13px;
}

footer#global_footer .ft_nav {
  background: #7d4329;
  padding: 10px;
}

footer#global_footer #copy a {
  text-decoration: none;
  color: #333;
}

footer#global_footer #copy a:hover {
  opacity: 0.6;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #copy a {
    line-height: 60px;
  }
}

#copy {
  color: #333;
}

.pagetop {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 2;
  opacity: 1;
}

.pagetop a {
  width: 50px;
  height: 50px;
  background: #7d4329;
  color: #fff;
  border-radius: 50%;
  font-size: 30px;
  display: block;
  padding: 10px 12px;
}

.ft_L {
  line-height: 1.3em;
  text-align: center;
  padding: 50px 0;
}

@media only screen and (max-width: 834px) {
  .ft_L {
    width: 100%;
    text-align: center;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .ft_L {
    padding-left: 10px;
  }
}

#footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  #footer {
    display: block;
  }
}

.ft1wrap {
  background: url(../images/common/cont_bg.jpg) center;
  background-size: cover;
  padding: 120px 0;
}

.ft1wrap .ftcont_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ft_sns {
  font-size: 2em;
  margin-top: 15px;
}

.ft_sns .twitter {
  color: #3AA1CC;
  font-weight: bold;
}

.ft_sns .insta {
  color: #B041E5;
  font-weight: bold;
  margin: 0 10px;
}

.ft_sns .fb {
  color: #415AAC;
  font-weight: bold;
}

#footer1 {
  padding: 0 !important;
}

#footer1 .fttxt {
  color: #ffffff;
  font-size: 1.5em;
  font-weight: bold;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  #footer1 .fttxt {
    justify-content: center;
    margin-bottom: 10px;
  }
}

#footer1 .ftcont_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  #footer1 .ftcont_wrap {
    display: block;
  }
}

#footer1 .ftcont_wrap .ftcont a {
  background: #ff6600;
  color: #ffffff;
  font-size: 1.25em;
  padding: 15px 0;
  border-radius: 5px;
  width: 300px;
  height: 60px;
  margin: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 834px) {
  #footer1 .ftcont_wrap .ftcont a {
    margin: auto;
  }
}

#footer1 .ftcont_wrap .ftcont a img {
  margin-right: 7px;
}

#footer1 .telwrap {
  background: #ab6300;
  padding: 15px 0;
  margin-right: 30px;
  border-radius: 5px;
  width: 300px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 834px) {
  #footer1 .telwrap {
    margin: 10px auto;
  }
}

#footer1 .tel2 {
  font-size: 1.5em;
  color: #ffffff;
  font-weight: bold;
}

#footer1 .tel2 img {
  margin-right: 7px;
}

@media only screen and (max-width: 640px) {
  #footer1 .tel2 {
    font-size: 1.25em;
    margin-right: 0;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #footer1 .tel2 {
    font-size: 1.25em;
  }
}

#footer1 .tel2 a {
  color: #ffffff;
  font-weight: bold;
  display: block;
}

/* box */
.h2_01 h2 {
  font-size: 1.8em;
  color: #ab6300;
  line-height: 1.2em;
  font-weight: bold;
}

.h2_01 h2 img {
  margin-right: 7px;
}

@media only screen and (max-width: 640px) {
  .h2_01 h2 {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .h2_01 h2 {
    font-size: 1.25em;
  }
}

.h2_02 h2 {
  font-size: 1.8em;
  line-height: 1.2em;
  font-weight: bold;
  color: #7d4329;
  display: flex;
  justify-content: center;
  align-items: center;
}

.h2_02 h2:before {
  content: url(../images/common/midashi_L.png);
  margin-right: 5px;
}

.h2_02 h2:after {
  content: url(../images/common/midashi_R.png);
  margin-left: 5px;
}

@media only screen and (max-width: 834px) {
  .h2_02 h2 {
    font-size: 1.3em;
  }
}

.txt {
  line-height: 2em;
}

.contbox {
  justify-content: center;
  position: relative;
}

.contbox:before {
  content: url(../images/common/maruta_icon.png);
  position: absolute;
  left: -14%;
  top: -18%;
  z-index: 1;
}

@media only screen and (max-width: 640px) {
  .contbox:before {
    left: 0;
    top: -12%;
  }
}

.contbox:after {
  content: url(../images/common/maruta_icon.png);
  position: absolute;
  right: -14%;
  bottom: -23%;
  z-index: 1;
}

@media only screen and (max-width: 640px) {
  .contbox:after {
    right: 0;
    bottom: -10%;
  }
}

.contbox .box {
  width: 30%;
  text-align: center;
  margin: 10px;
}

@media only screen and (max-width: 640px) {
  .contbox .box {
    width: 100% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .contbox .box {
    width: 45%;
  }
}

.contbox .box .title {
  text-align: center;
  font-size: 1.25em;
  font-weight: bold;
  color: #ab6300;
  margin: 15px 0;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .contbox .box .title {
    font-size: 1em;
  }
}

.contbox .box .title span {
  color: #ff6600;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.contbox .box .title span:after {
  content: '';
  width: 25px;
  height: 1px;
  background: #ab6300;
  display: inline-block;
}

.contbox .box .cont_txt {
  text-align: left;
  background: #FAF6E6;
  padding: 10px;
  height: 310px;
}

.btn a {
  display: block;
  padding: 10px 0;
  color: #ffffff;
  font-weight: bold;
  background: url(../images/common/btn_bg.png) no-repeat center;
  position: relative;
  border-radius: 50px;
  width: 250px;
  margin: 0 auto;
}

.btn a:after {
  content: '→';
  color: #ffffff;
  position: absolute;
  right: 6%;
  top: 20%;
}

.sec_01wrap {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  background: #FAF6E6;
  padding: 60px 0;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .sec_01wrap {
    padding: 30px 0;
  }
}

.sec_01wrap:after {
  content: "";
  background: url(../images/common/nami_under.png) repeat-x;
  height: 100%;
  position: absolute;
  opacity: 0.8;
  bottom: -100%;
  width: 100%;
  overflow: hidden;
  -moz-animation: infinity-loop 30s infinite linear 1s both;
  -webkit-animation: infinity-loop 30s infinite linear 1s both;
  animation: infinity-loop 30s infinite linear 1s both;
  z-index: -1;
}

.sec_01 {
  padding: 0 10px;
}

@-webkit-keyframes infinity-loop {
  from {
    background-position: 0  0;
  }
  to {
    background-position: -1920px 0;
  }
}

@keyframes infinity-loop {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -1920px 0;
  }
}

.sec_02wrap {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  background: #FAF6E6;
  padding: 60px 0;
  position: relative;
}

.sec_02wrap:after {
  content: "";
  background: url(../images/common/nami_under.png) repeat-x;
  height: 100%;
  position: absolute;
  opacity: 0.8;
  bottom: -100%;
  width: 100%;
  overflow: hidden;
  -moz-animation: infinity-loop 30s infinite linear 1s both;
  -webkit-animation: infinity-loop 30s infinite linear 1s both;
  animation: infinity-loop 30s infinite linear 1s both;
  z-index: -1;
}

.sec_02wrap:before {
  content: "";
  background: url(../images/common/nami_top.png) repeat-x;
  height: 100%;
  position: absolute;
  opacity: 0.8;
  top: -6%;
  width: 100%;
  overflow: hidden;
  -moz-animation: infinity-loop 30s infinite linear 1s both;
  -webkit-animation: infinity-loop 30s infinite linear 1s both;
  animation: infinity-loop 30s infinite linear 1s both;
  z-index: -1;
}

.sec_02 {
  position: relative;
}

.sec_02:before {
  content: url(../images/common/kabu_icon.png);
  position: absolute;
  left: -14%;
  top: -16%;
}

@media only screen and (max-width: 640px) {
  .sec_02:before {
    left: 0;
  }
}

.sec_02:after {
  content: url(../images/common/maruta2_icon.png);
  position: absolute;
  right: -14%;
  bottom: -18%;
}

@media only screen and (max-width: 640px) {
  .sec_02:after {
    right: 0;
  }
}

.imgbox {
  justify-content: space-between;
}

.imgbox .box {
  text-align: center;
  color: #ff6600;
  margin: 10px;
  width: 22%;
}

@media only screen and (max-width: 834px) {
  .imgbox .box {
    width: 45%;
  }
}

.imgbox .box img {
  border-radius: 10px;
  border: 5px solid #fff;
  width: 100% !important;
}

.btnbox {
  justify-content: space-between;
}

.btnbox .box {
  position: relative;
  width: 32%;
}

.btnbox .box img {
  width: 100% !important;
}

@media only screen and (max-width: 640px) {
  .btnbox .box {
    width: 100%;
    height: 200px;
  }
  .btnbox .box:nth-of-type(2) {
    margin-top: 30px;
  }
  .btnbox .box:nth-of-type(3) {
    margin-top: 30px;
  }
}

.btnbox .box .kakomi {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100px;
  color: #fff;
  font-size: 26px;
  text-align: center;
}

.btnbox .box .kakomi a {
  color: #fff;
  display: block;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.btnbox .box .kakomi a:before {
  content: url(../images/common/bnr_icon.png);
  display: block;
  margin-bottom: 10px;
}

.btnbox .box .kakomi a:after {
  content: url(../images/common/bnr_icon.png);
  display: block;
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  .btnbox .box .kakomi {
    font-size: 1.6em;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .btnbox .box .kakomi {
    font-size: 1.3em;
  }
}

.btnbox h3 {
  display: none;
}

.list li {
  font-size: 13px;
  background: #f4f3e9;
  margin: 5px;
  padding: 0 3px;
}

.img-animation {
  overflow: hidden;
  position: relative;
}

.img-animation.animated:after {
  -webkit-animation: img-animation 1.5s cubic-bezier(0.77, 0, 0.175, 1) both;
  animation: img-animation 1.5s cubic-bezier(0.77, 0, 0.175, 1) both;
  background: #fff;
  bottom: 0;
  content: '';
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

@-webkit-keyframes img-animation {
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes img-animation {
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@media only screen and (max-width: 640px) {
  .newswrap {
    padding: 0 10px;
  }
}

.newswrap .news {
  height: 200px;
  overflow-y: auto;
}

@media only screen and (max-width: 834px) {
  .newswrap .news {
    padding-left: 0;
    font-size: 16px;
  }
}

.newswrap .news dt {
  border: none !important;
  padding: 0 !important;
}

@media only screen and (max-width: 640px) {
  .newswrap .news dt {
    display: block !important;
    width: 100% !important;
  }
}

.newswrap .news dd {
  border: none !important;
  padding: 0 !important;
}

@media only screen and (max-width: 640px) {
  .newswrap .news dd {
    width: 100% !important;
  }
}

.newswrap .news dl {
  border-bottom: 1px dashed #7d4329 !important;
  padding: 20px 0 !important;
  align-items: flex-start;
}

@media only screen and (max-width: 640px) {
  .newswrap .news dl {
    display: block !important;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #fff;
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 0 2px #777;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  box-shadow: none;
}

.home_td {
  margin-left: -7.69231% !important;
  display: inline-table;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .home_td {
    margin-left: -7.69231% !important;
  }
}

@media only screen and (max-width: 640px) {
  .home_td {
    margin-left: 0 !important;
  }
}

.home_td .box {
  z-index: 10;
  float: left;
  width: 42.30769% !important;
  margin-left: 7.69231% !important;
  margin-bottom: 30px !important;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .home_td .box {
    width: 42.30769% !important;
    margin-left: 7.69231% !important;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .home_td .box {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.home_td .box img {
  width: 100% !important;
  border-radius: 15px;
}

.home_td .box .cont_titl {
  display: block;
  margin: 30px 0 10px;
}

.home_td .box .cont_titl h3 {
  font-size: 25px;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3 {
    font-size: 20px;
  }
}

.home_td .box .cont_titl h3:first-letter {
  font-size: 35px;
  color: #ef9916;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3:first-letter {
    font-size: 30px;
  }
}

/* title */
.h3_01 h3 {
  font-size: 1.25em;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: #7d4329;
}

.h3_01 h3:before {
  content: url(../images/common/midashi_icon1.png);
  margin-right: 7px;
}

.sec_03 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FAF6E6;
  padding: 30px;
}

@media only screen and (max-width: 834px) {
  .sec_03 {
    display: block;
  }
  .sec_03 .flxL {
    width: 100% !important;
  }
  .sec_03 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.sec_03 .flxL {
  width: 48%;
  text-align: center;
}

.sec_03 .flxR {
  width: 51%;
  padding: 10px;
}

.imgbox2 {
  justify-content: center;
}

.imgbox2 .box {
  width: 22%;
  margin: 10px;
}

.imgbox2 .box img {
  width: 100% !important;
  height: 180px !important;
  object-fit: cover;
  font-family: 'object-fit:cover;';
}

@media only screen and (max-width: 834px) {
  .imgbox2 .box {
    width: 45%;
  }
}

.txt {
  line-height: 2em;
}

.cont_tel div {
  font-size: 1.8em;
  font-weight: bold;
}

.cont_tel a {
  color: #7d4329;
  font-weight: bold;
}

.cont_form {
  padding: 30px;
  background: #FAF6E6;
}

.cont_form dl {
  border-bottom: 1px solid #fff;
  margin: 10px;
}

.dl_01 {
  max-width: 800px;
  margin: 0 auto;
}

@media only screen and (max-width: 834px) {
  .dl_01 {
    max-width: 100% !important;
  }
}

.dl_01 dt {
  border: none !important;
  background: #ab6300 !important;
  color: #ffffff;
  text-align: center;
  margin: 5px;
  align-items: center !important;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .dl_01 dt {
    display: block !important;
    width: 100% !important;
  }
}

.dl_01 dd {
  border: none !important;
  background: #FAF6E6 !important;
  margin: 5px;
}

@media only screen and (max-width: 640px) {
  .dl_01 dd {
    width: 100% !important;
  }
}

@media only screen and (max-width: 640px) {
  .dl_01 dl {
    display: block !important;
  }
}

.dl_02 {
  max-width: 800px;
  margin: 0 auto;
}

@media only screen and (max-width: 834px) {
  .dl_02 {
    max-width: 100% !important;
  }
}

.dl_02 dt {
  border: none !important;
  background: #ab6300 !important;
  color: #ffffff;
  text-align: center;
  margin: 5px;
  align-items: center !important;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .dl_02 dt {
    display: block !important;
    width: 100% !important;
  }
}

.dl_02 dd {
  border: none !important;
  background: #FAF6E6 !important;
  margin: 5px;
}

@media only screen and (max-width: 640px) {
  .dl_02 dd {
    width: 100% !important;
  }
}

@media only screen and (max-width: 640px) {
  .dl_02 dl {
    display: block !important;
  }
}

.qa dl {
  display: block !important;
  margin: 15px 0;
  background: #FAF6E6;
  padding: 15px;
}

.qa dt {
  display: block !important;
  width: 100% !important;
  border: none !important;
  position: relative;
  padding: 15px 0 15px 45px !important;
}

@media only screen and (max-width: 834px) {
  .qa dt {
    padding-right: 45px !important;
  }
}

.qa dd {
  width: 100% !important;
  border: none !important;
  padding: 15px 0 15px 45px !important;
  position: relative;
}

.qa dd span {
  font-size: 1.25em;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: bold;
}

.qa dt:before {
  content: "Q";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #7d4329;
  font-weight: bold;
  width: 40px;
  text-align: center;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 10px;
}

.qa dd:before {
  content: "A";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #ff6600;
  font-weight: bold;
  width: 40px;
  text-align: center;
  height: 40px;
  margin-right: 7px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 10px;
}

.cont_form a {
  color: #333333;
}

.cont_form a:hover {
  text-decoration: underline;
}

.con100 {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.gmap {
  z-index: 0;
}

.gmap iframe {
  width: 100%;
}

.slider2 img {
  margin: 0 auto;
}

.bg_100per_wrap {
  position: relative;
  padding: 500px 0 !important;
}

@media only screen and (max-width: 640px) {
  .bg_100per_wrap {
    padding: 550px 0 !important;
  }
}

.bg_100per_inner {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/cont2_bg.jpg);
  background-size: cover;
  z-index: 0;
  display: flex;
  justify-content: flex-end;
  background-attachment: fixed;
}

@media only screen and (max-width: 834px) {
  .bg_100per_inner {
    display: block;
  }
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 1px solid #7d4329;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.policy {
  padding: 10px 0;
  text-align: center;
}

.policy a {
  color: #7d4329;
}

#TRANS_ANNOT {
  display: none;
}

.slide-bottom {
  opacity: 0;
  transform: translate(0, 50px);
  transition: all 1500ms;
}

.slide-bottom.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-top {
  opacity: 0;
  transform: translate(0, -50px);
  transition: all 1500ms;
}

.slide-top.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-right {
  opacity: 0;
  transform: translate(50px, 0px);
  transition: all 1500ms;
}

.slide-right.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-left {
  opacity: 0;
  transform: translate(-50px, 0px);
  transition: all 1500ms;
}

.slide-left.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

@keyframes fadeIn_left {
  0% {
    opacity: 0;
    transform: translate(-50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn_right {
  0% {
    opacity: 0;
    transform: translate(50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(0, 50px);
  }
  100% {
    opacity: 1;
  }
}

.sns {
  margin-top: 50px;
}

#media {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
}

#media .row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

#media .row .box {
  width: 31%;
  margin-bottom: 50px;
}

@media only screen and (max-width: 640px) {
  #media .row .box {
    width: 100% !important;
  }
}

#media .row .box h3 {
  text-align: left;
  margin-top: 15px;
}

#media .row .box p {
  text-align: left;
}

#media .row .box img {
  width: 380px !important;
  height: 380px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}
